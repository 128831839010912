import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Past Experiences"
				body={
					<div className="works-body">
						<div className="work">
							<div className="work-header">
								<img
									src="./govtech.jpg"
									alt="Govtech"
									className="work-image"
								/>
								<div className="work-title">GovTech</div>
							</div>
							<div className="work-main">
								<div className="work-subtitle">
									Software Engineer
								</div>
								<div className="work-text">
									<ul>
										<li>
											Full-stack developer for
											Cloak.gov.sg, a government privacy
											toolkit powered with AIML, with
											k-anonymity, anonymization and
											synthetic data generation. Used by
											100 agencies and 1.5k+ government
											officers to anonymize 3k+ datasets
											and handle 10M+ API calls.
										</li>
										<li>
											Applied cryptographic techniques
											such as encryption and hashing to
											ensure data privacy and security
											within Cloak’s anonymisation
											services.
										</li>
										<li>
											Set up AWS VPCE and roles for secure
											data integration with the Ministry
											of Social and Family Development
											(MSF) systems’ with Cloak,
											transforming bulk data (up to
											1GB/file) and supporting daily
											pipeline updates.
										</li>
										<li>
											Contributed to the development of
											Cloak’s backlogged features,
											leveraging Next.js for the frontend
											and the Serverless Framework for the
											backend, while implementing RESTful
											APIs for seamless system
											integration.{" "}
										</li>
										<li>
											Facilitated deployment using
											ECS/Fargate, Lambda Functions, and
											Step Functions, optimizing compute
											efficiency and achieving cost
											savings of thousands of dollars.{" "}
										</li>
										<li>
											Implemented automated infrastructure
											deployment (IAC) using Terraform,
											CloudFormation and Docker.{" "}
										</li>
										<li>
											Refactored backend components to
											utilize a unified backend API,
											enhancing maintainability and
											scalability.
										</li>
										<li>
											Conducted UAT and Unit Testing to
											ensure new features met requirements
											and improved application
											performance.
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="work">
							<div className="work-header">
								<img
									src="./smu.jpg"
									alt="SMU"
									className="work-image"
								/>
								<div className="work-title">
									Singapore Management University
								</div>
							</div>
							<div className="work-main">
								<div className="work-subtitle">AI Engineer</div>
								<div className="work-text">
									<ul>
										<li>
											Developed and deployed a chatbot
											system to enhance student inquiry
											and facilitate better assessment by
											professors, using Pinecone,
											LangChain, and Node.js, deployed to
											AWS.
										</li>
										<li>
											Developed a React webapp for user
											interaction and implemented sockets
											for group interactions.
										</li>
										<li>
											Used by approximately 450 students
											and 10 professors each semester,
											facilitating around 900
											student-professor
										</li>
										interactions annually, with an average
										of 9,000 API calls.
									</ul>
								</div>
							</div>
						</div>

						<div className="work">
							<div className="work-header">
								<img
									src="./3dmed.png"
									alt="3dmed"
									className="work-image"
								/>
								<div className="work-title">3DMedcare</div>
							</div>
							<div className="work-main">
								<div className="work-subtitle">
									Software Application Engineer
								</div>
								<div className="work-text">
									<ul>
										<li>
											Utilized Python, shell, bash scripts
											to automate system testing for
											system engineers.
										</li>
										<li>
											Spearheaded back-end development
											efforts for a GUI application using
											C programming language.
										</li>
										<li>
											Collaborated with an external UI
											company for front-end development,
											employing HTML and JavaScript.
										</li>
										<li>
											Monitored and provided assistance
											with software testing and validation
											procedures, ensuring quality
											control.
										</li>
									</ul>
								</div>
								<div className="work-subtitle">
									Firmware Software Engineer Intern
								</div>
								<div className="work-text">
									<ul>
										<li>
											Collaborated with Mechanical
											Engineering (ME), Electrical
											Engineering (EE), and Systems teams
											to ensure the success of the
											product.
										</li>
										<li>
											Implemented database testing
											procedures to ensure the integrity
											and functionality of the system.
										</li>
										<li>
											Developed a Microsoft Service
											Installer to facilitate the
											installation of a back-end service,
											streamlining deployment processes.
										</li>
										<li>
											Implemented a bug tracking tool to
											effectively track and manage
											software issues, enhancing project
											management and resolution
											efficiency.
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="work">
							<div className="work-header">
								<img
									src="./myrepublic.jpg"
									alt="myrepublic"
									className="work-image"
								/>
								<div className="work-title">MyRepublic</div>
							</div>
							<div className="work-main">
								<div className="work-subtitle">
									Associate Software Engineer
								</div>
								<div className="work-text">
									<ul>
										<li>
											Integrated the Chatbot with Facebook
											and Zendesk platforms to broaden its
											availability and support multiple
											platforms seamlessly.
										</li>
										<li>
											Successfully deployed the Chatbot
											application to an instance on Amazon
											Web Service (AWS), facilitating
											accessibility and scalability.
										</li>
										<li>
											Targeted and resolved user story
											issues identified in the chat
											history between customers and the
											company, ensuring optimal user
											experience.
										</li>
										<li>
											Implemented robust security features
											on the server to safeguard sensitive
											data and ensure the integrity of the
											Chatbot application.
										</li>
									</ul>
								</div>
								<div className="work-subtitle">
									Site Reliability Engineer cum Software
									Engineer Intern
								</div>
								<div className="work-text">
									<ul>
										<li>
											Created instances on Amazon Web
											Service (AWS) to host and support
											various applications.
										</li>
										<li>
											Ensured security and availability of
											servers by implementing rules and
											failover mechanisms, enhancing
											reliability and protection against
											potential threats.
										</li>
										<li>
											Collaborated with a team to develop
											a Chatbot application aimed at
											enhancing customer service
											experiences.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
