const INFO = {
	main: {
		title: "Timo's Portfolio",
		name: "Timothy Huang Kerong",
		email: "timothykerong@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/timohkr",
		linkedin: "https://www.linkedin.com/in/timothy-huang-06a31a126/",
	},

	homepage: {
		title: "Hello!",
		description:
			"I am Timothy, a final year Computer Science student at SMU, specialising in AI & Cyber Security.\n\nI dabble in fullstack development, mainly with Node.js. I've had some fun building all sorts of web apps that are scalable, and safe.\n\nI enjoy solving complex problems and learning new skills. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "Who Am I ?",
		description:
		"Hey there, I'm Timothy, a Computer Science student in Singapore Management University. \n\nI've got a thing for Artificial Intelligence, Cybersecurity, Software Engineering, and Web Development. \n\nI've worked on a variety of projects over the years but I mainly hustle in Web development, where I've been putting in the work as a software engineer during my internships. \n\nBelow are my certificates that I have achieved throughout my years."
	},

	projects: [
		{
			title: "GrabMate",
			description:
				"Headless CMS service using Payload CMS (Collaboration with Grab).",
			logo: ["../payloadcms.png"],
			linkText: "View Project",
			// link: "https://github.com/GrabMate/backend",
		},

		{
			title: "Lang Plaza",
			description:
				"Application that facilitates language learning through real-life practice.",
			logo: ["../nextjs.png", "../react.png", "../typescript.png"],
			linkText: "View Project",
			link: "https://github.com/AdH01C/langplaza",
		},

		{
			title: "Auth Server",
			description:
				"An authentication service that enables customers to utilize managed user services (Project by Ascenda Loyalty).",
			logo: ["../nodejs.png", "../react.png"],
			linkText: "View Project",
			link: "https://github.com/timohkr/authserver",
		},

		{
			title: "WasteAway",
			description:
				"Addressing the issue of climate change, the application centers around waste management by facilitating a platform for users to give away excess items, fostering sustainability.",
			logo: ["../springboot.png", "../react.png"],
			linkText: "View Project",
			link: "https://github.com/sktt2/WasteAway",
		},

		{
			title: "Password Strength Meter",
			description:
				"Tool to measure the effectiveness of a password against a brute force attack.",
			logo: ["../nodejs.png", "../react.png", "../tailwind.png"],
			linkText: "View Project",
			link: "https://github.com/CS440-project/CS440-Password-Strength-Meter",
		},
	],
};

export default INFO;
