import React from "react";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/certs.css";

const Certs = () => {
	return (
		<div className="certs">
			<Card
				icon={faCertificate}
				title="Certificates"
				body={
					<div className="certs-body">
						<div className="cert">
							<div className="cert-header">
							<img
								src="./aws.jpg"
								alt="aws"
								className="cert-image"
							/>
							<div className="cert-title">AWS Certified Solutions Architect – Associate</div>
							</div>
							<div className="cert-main">
								<div className="cert-subtitle">
									Amazon Web Services (AWS)
								</div>
								<div className="cert-text"> 
                                    Issued: Oct 2023
                                    <br/> 
                                    Expires: Oct 2026
                                    <br/>
                                    Credential ID: WKPL2BYKVMF11PWZ
                                    <br/>
                                    <a href="https://www.credly.com/badges/63a2ac23-80bb-4b43-9e4d-eb5589ff7885" target="_blank" rel="noreferrer">Show Credential</a>
								</div>
							</div>
						</div>

						<div className="cert">
							<div className="cert-header">
							<img
								src="./oracle.jpg"
								alt="oracle"
								className="cert-image"
							/>
							<div className="cert-title">Oracle Certified Foundations Associate, Java</div>
							</div>
							<div className="cert-main">
								<div className="cert-subtitle">
								Oracle
								</div>
								<div className="cert-text" > 
                                    Issued: Mar 2022
                                    <br/> 
                                    <a href="https://www.credly.com/badges/061374df-bbef-4d49-b492-e96fdae89820?source=linked_in_profile" target="_blank" rel="noreferrer">Show Credential</a>
								</div>
							</div> 
						</div>

						<div className="cert">
							<div className="cert-header">
							<img
								src="./heicoder.jpg"
								alt="heicoder"
								className="cert-image"
							/>
							<div className="cert-title">AI200: Applied Machine Learning</div>
							</div>
							<div className="cert-main">
								<div className="cert-subtitle">
								Heicoders Academy (Professionals)
								</div>
                                <div className="cert-text"> 
                                    Issued: Dec 2021
                                    <br/>
                                    Credential ID: CjTtG72qUEKLEKfT
                                    <br/>
                                    <a href="https://cert.heicodersacademy.com/CjTtG72qUEKLEKfT" target="_blank" rel="noreferrer">Show Credential</a>
								</div>
							</div> 
						</div>

						<div className="cert">
							<div className="cert-header">
							<img
								src="./heicoder.jpg"
								alt="heicoder"
								className="cert-image"
							/>
							<div className="cert-title">AI100: Python Programming and Data Visualisation</div>
							</div>
							<div className="cert-main">
								<div className="cert-subtitle">
								Heicoders Academy (Professionals)
								</div>
                                <div className="cert-text"> 
                                    Issued: Oct 2021
                                    <br/>
                                    Credential ID: B0JI2BknVsE33d5D
                                    <br/>
                                    <a href="https://cert.heicodersacademy.com/B0JI2BknVsE33d5D" target="_blank" rel="noreferrer">Show Credential</a>
								</div>
							</div> 
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Certs;
